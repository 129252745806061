import {
    CHARACTERS,
    ELEMENTS,
    PATHS,
    PLANAR_SETS,
    RELIC_SETS,
    RELIC_SLOTS,
    RELIC_STATS,
} from './constants.js';
import { LIGHTCONE_IDS } from './lib/lightcones/lightcone_helpers.js';

export const RELIC_WEIGHTS = {
    HIGHEST: 10,
    HIGH: 8,
    MEDIUM: 5,
    LOWEST: 2,
}

const RELIC_SETS_ATK = [
    RELIC_SETS.DOT,
    RELIC_SETS.MUSKETEER,
    RELIC_SETS.VALOROUS,
];

const PLANAR_SETS_ENERGY = [
    PLANAR_SETS.LUSHAKA,
    PLANAR_SETS.PENACONY,
    PLANAR_SETS.VONWACQ,
]

const LIGHTCONES_ERUDITION = [
    LIGHTCONE_IDS.ERUDITION_GENIUS,
    LIGHTCONE_IDS.ERUDITION_JY,
    LIGHTCONE_IDS.ERUDITION_HIMEKO,
    LIGHTCONE_IDS.ERUDITION_PEACEFULDAY,
    LIGHTCONE_IDS.ERUDITION_CALCULUS,
    LIGHTCONE_IDS.ERUDITION_ARGENTI,
    LIGHTCONE_IDS.ERUDITION_COSMOS,
    LIGHTCONE_IDS.ERUDITION_BREAKFAST,
]

export const CHARACTER_BUILD_DATA = [
    {
        character: CHARACTERS.ACHERON,
        searchTerms: [],
        path: PATHS.NIHILITY,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_ACHERON,
            LIGHTCONE_IDS.NIHILITY_GNSW,
            LIGHTCONE_IDS.NIHILITY_CHOREO,
            LIGHTCONE_IDS.NIHILITY_SW,
            LIGHTCONE_IDS.NIHILITY_WELT,
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.QUANTUM,
            RELIC_SETS.LIGHTNING,
            RELIC_SETS.SPEED,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.LIGHTNING, RELIC_SETS.SPEED, RELIC_SETS.DOT, RELIC_SETS.MUSKETEER, RELIC_SETS.VALOROUS],
        '4pc': [RELIC_SETS.QUANTUM],
        planarSets: [
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.SALSOTTO,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ATK, RELIC_STATS.LIGHTNING_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            }
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.ARGENTI,
        searchTerms: [],
        path: PATHS.ERUDITION,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            ...LIGHTCONES_ERUDITION
        ],
        relicSets: [
            RELIC_SETS.PHYSICAL,
            RELIC_SETS.PIONEER,
            RELIC_SETS.QUANTUM,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [RELIC_SETS.QUANTUM],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SIGONIA,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.SSS,
            PLANAR_SETS.RUTILANT,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.PHYSICAL_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.ARLAN,
        searchTerms: [],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_BLADE,
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SECRETVOW,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_MOLES,
        ],
        relicSets: [
            RELIC_SETS.HP,
            RELIC_SETS.LIGHTNING,
            RELIC_SETS.PIONEER,
            RELIC_SETS.QUANTUM,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [RELIC_SETS.QUANTUM],
        planarSets: [
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.LIGHTNING_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
        ],
    },
    {
        character: CHARACTERS.ASTA,
        searchTerms: [],
        path: PATHS.HARMONY,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_DDD,
            LIGHTCONE_IDS.HARMONY_MOTP,
            LIGHTCONE_IDS.HARMONY_COGS,
            LIGHTCONE_IDS.HARMONY_PLANETARY,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.WATCHMAKER,
        ],
        '2pc': [RELIC_SETS.CAVALRY],
        '4pc': [RELIC_SETS.MUSKETEER],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            ...PLANAR_SETS_ENERGY,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.AVENTURINE,
        searchTerms: [],
        path: PATHS.PRESERVATION,
        element: ELEMENTS.IMAGINARY,
        lightcones: [
            LIGHTCONE_IDS.PRESERVATION_AVENTURINE,
            LIGHTCONE_IDS.PRESERVATION_GEPARD,
            LIGHTCONE_IDS.PRESERVATION_CONCERT,
            LIGHTCONE_IDS.PRESERVATION_DESTINY,
            LIGHTCONE_IDS.PRESERVATION_TREND,
        ],
        relicSets: [
            RELIC_SETS.SHIELD,
            RELIC_SETS.PIONEER,
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.SPEED,
            RELIC_SETS.IMAGINARY
        ],
        '2pc': [RELIC_SETS.FOLLOWUP, RELIC_SETS.SPEED, RELIC_SETS.IMAGINARY],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.DURAN,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.PENACONY,
            PLANAR_SETS.BELEBOG,
            PLANAR_SETS.FLEET,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.DEF, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.DEF, RELIC_STATS.IMAGINARY_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.DEF],
            },
        ],
        substats: [
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST }],
    },
    {
        character: CHARACTERS.BAILU,
        searchTerms: [],
        path: PATHS.ABUNDANCE,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            LIGHTCONE_IDS.ABUNDANCE_MULTIPLICATION,
            LIGHTCONE_IDS.ABUNDANCE_HUOHUO,
            LIGHTCONE_IDS.ABUNDANCE_BAILU,
            LIGHTCONE_IDS.ABUNDANCE_POSTOP,
            LIGHTCONE_IDS.ABUNDANCE_PERFECTTIMING,
            LIGHTCONE_IDS.ABUNDANCE_QPQ,
        ],
        relicSets: [
            RELIC_SETS.HEAL,
            RELIC_SETS.SPEED,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD
        ],
        '2pc': [RELIC_SETS.HEAL, RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            ...PLANAR_SETS_ENERGY,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.HP, RELIC_STATS.OUTGOING_HEALING],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.HP],
            }
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.BLACK_SWAN,
        searchTerms: ["bs"],
        path: PATHS.NIHILITY,
        element: ELEMENTS.WIND,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_JIAOQIU,
            LIGHTCONE_IDS.NIHILITY_BS,
            LIGHTCONE_IDS.NIHILITY_KAFKA,
            LIGHTCONE_IDS.NIHILITY_EOTP,
            LIGHTCONE_IDS.NIHILITY_GNSW,
            LIGHTCONE_IDS.NIHILITY_SHOWTIME,
        ],
        relicSets: [
            RELIC_SETS.DOT,
            RELIC_SETS.PIONEER,
            RELIC_SETS.WIND,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.SPEED,
        ],
        '2pc': [RELIC_SETS.SPEED],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
            PLANAR_SETS.PANCOSMIC,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.EFFECT_HIT_RATE, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK, RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.WIND_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.BLADE,
        searchTerms: [],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.WIND,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_BLADE,
            LIGHTCONE_IDS.DESTRUCTION_SECRETVOW,
            LIGHTCONE_IDS.DESTRUCTION_FLAMESAFAR,
            LIGHTCONE_IDS.DESTRUCTION_AEON
        ],
        relicSets: [
            RELIC_SETS.HP,
            RELIC_SETS.PIONEER,
            RELIC_SETS.WIND,
            RELIC_SETS.SPEED
        ],
        '2pc': [RELIC_SETS.SPEED],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.RUTILANT,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.WIND_DMG, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.HP],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.BOOTHILL,
        searchTerms: ["bh"],
        path: PATHS.HUNT,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.HUNT_BOOTHILL,
            LIGHTCONE_IDS.HUNT_SHADOWED,
            LIGHTCONE_IDS.HUNT_ADVERSARIAL,
            LIGHTCONE_IDS.HUNT_RIVER,
        ],
        relicSets: [
            RELIC_SETS.CAVALRY,
            RELIC_SETS.BREAK,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.QUANTUM,
            RELIC_SETS.SPEED
        ],
        '2pc': [RELIC_SETS.WATCHMAKER, RELIC_SETS.SPEED],
        '4pc': [RELIC_SETS.QUANTUM],
        planarSets: [
            PLANAR_SETS.TALIA,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.GLAMOTH
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.PHYSICAL_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.BRONYA,
        searchTerms: [],
        path: PATHS.HARMONY,
        element: ELEMENTS.WIND,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_BRONYA,
            LIGHTCONE_IDS.HARMONY_PNF,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.SPEED, RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [RELIC_SETS.MUSKETEER],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.PENACONY,
            PLANAR_SETS.LUSHAKA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.DEF, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.CLARA,
        searchTerms: [],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_YUNLI,
            LIGHTCONE_IDS.DESTRUCTION_DHIL,
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_CLARA,
            LIGHTCONE_IDS.DESTRUCTION_BLADE,
            LIGHTCONE_IDS.DESTRUCTION_NOWHERETORUN,
            LIGHTCONE_IDS.DESTRUCTION_WOOF,
        ],
        relicSets: [
            RELIC_SETS.PHYSICAL,
            RELIC_SETS.PIONEER,
            RELIC_SETS.HP,
            RELIC_SETS.FOLLOWUP,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.FOLLOWUP, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.DURAN,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.SSS,
            PLANAR_SETS.SIGONIA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.PHYSICAL_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST }
        ],
    },
    {
        character: CHARACTERS.DAN_HENG,
        searchTerms: ["dh"],
        path: PATHS.HUNT,
        element: ELEMENTS.WIND,
        lightcones: [
            LIGHTCONE_IDS.HUNT_SEELE,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_SILENCE,
            LIGHTCONE_IDS.HUNT_YANQING
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.WIND,
            RELIC_SETS.SPEED,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.SPEED, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.SSS,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.RUTILANT,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK, RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.WIND_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.DAN_HENG_IL,
        searchTerms: ["dhil"],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.IMAGINARY,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_DHIL,
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
        ],
        relicSets: [
            RELIC_SETS.IMAGINARY,
            RELIC_SETS.PIONEER,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.SSS,
            PLANAR_SETS.SALSOTTO,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.IMAGINARY_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.DR_RATIO,
        searchTerms: ["dr ratio", "doctor ratio"],
        path: PATHS.HUNT,
        element: ELEMENTS.IMAGINARY,
        lightcones: [
            LIGHTCONE_IDS.HUNT_RATIO,
            LIGHTCONE_IDS.HUNT_TOPAZ,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_SILENCE
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.IMAGINARY,
            RELIC_SETS.FOLLOWUP,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.DURAN,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.IMAGINARY_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.FEIXIAO,
        searchTerms: ['fx'],
        path: PATHS.HUNT,
        element: ELEMENTS.WIND,
        lightcones: [
            LIGHTCONE_IDS.HUNT_FEIXIAO,
            LIGHTCONE_IDS.HUNT_RATIO,
            LIGHTCONE_IDS.HUNT_TOPAZ,
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_SILENCE,
        ],
        relicSets: [
            RELIC_SETS.VALOROUS,
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.PIONEER,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.PIONEER, RELIC_SETS.MUSKETEER, RELIC_SETS.DOT],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.DURAN,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.GLAMOTH
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.WIND_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ]
    },
    {
        character: CHARACTERS.FIREFLY,
        searchTerms: ['ff', 'sam'],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_FIREFLY,
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_PROMISE,
            LIGHTCONE_IDS.DESTRUCTION_NOWHERETORUN,
            LIGHTCONE_IDS.DESTRUCTION_CLARA,
        ],
        relicSets: [
            RELIC_SETS.CAVALRY,
            RELIC_SETS.QUANTUM,
            RELIC_SETS.BREAK,
            RELIC_SETS.WATCHMAKER,
            ...RELIC_SETS_ATK
        ],
        '2pc': [
            RELIC_SETS.WATCHMAKER,
            ...RELIC_SETS_ATK
        ],
        '4pc': [RELIC_SETS.QUANTUM],
        planarSets: [
            PLANAR_SETS.KALPAGNI,
            PLANAR_SETS.TALIA,
            PLANAR_SETS.SSS,
            PLANAR_SETS.GLAMOTH,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST },
        ]
    },
    {
        character: CHARACTERS.FU_XUAN,
        searchTerms: ["fx"],
        path: PATHS.PRESERVATION,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            LIGHTCONE_IDS.PRESERVATION_FX,
            LIGHTCONE_IDS.PRESERVATION_GEPARD,
            LIGHTCONE_IDS.PRESERVATION_LANDAUS,
            LIGHTCONE_IDS.PRESERVATION_D1,
            LIGHTCONE_IDS.PRESERVATION_TREND,
            LIGHTCONE_IDS.PRESERVATION_TOM,
        ],
        relicSets: [
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
            RELIC_SETS.SPEED,
            RELIC_SETS.HEAL
        ],
        '2pc': [RELIC_SETS.HP, RELIC_SETS.GUARD, RELIC_SETS.SPEED, RELIC_SETS.HEAL],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.FLEET,
            PLANAR_SETS.KEEL,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.HP],
            },
        ],
        substats: [
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ]
    },
    {
        character: CHARACTERS.GALLAGHER,
        searchTerms: [],
        path: PATHS.ABUNDANCE,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.ABUNDANCE_QPQ,
            LIGHTCONE_IDS.ABUNDANCE_MULTIPLICATION,
            LIGHTCONE_IDS.ABUNDANCE_LINGSHA,
            LIGHTCONE_IDS.ABUNDANCE_LUOCHA,
            LIGHTCONE_IDS.ABUNDANCE_WHATISREAL,
            LIGHTCONE_IDS.ABUNDANCE_PERFECTTIMING,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.HEAL,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.BREAK,
        ],
        '2pc': [RELIC_SETS.SPEED, RELIC_SETS.HEAL, RELIC_SETS.WATCHMAKER, RELIC_SETS.BREAK],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KALPAGNI,
            PLANAR_SETS.TALIA,
            PLANAR_SETS.KEEL,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.OUTGOING_HEALING],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.GEPARD,
        searchTerms: [],
        path: PATHS.PRESERVATION,
        element: ELEMENTS.ICE,
        lightcones: [
            LIGHTCONE_IDS.PRESERVATION_GEPARD,
            LIGHTCONE_IDS.PRESERVATION_LANDAUS,
            LIGHTCONE_IDS.PRESERVATION_D1,
            LIGHTCONE_IDS.PRESERVATION_TREND,
        ],
        relicSets: [
            RELIC_SETS.SHIELD,
        ],
        '2pc': [],
        '4pc': [RELIC_SETS.SHIELD],
        planarSets: [
            PLANAR_SETS.BELEBOG,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.KEEL,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.GUINAIFEN,
        searchTerms: [],
        path: PATHS.NIHILITY,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_GNSW,
            LIGHTCONE_IDS.NIHILITY_EOTP,
            LIGHTCONE_IDS.NIHILITY_WELT,
            LIGHTCONE_IDS.NIHILITY_SHOWTIME,
            LIGHTCONE_IDS.NIHILITY_PEARLS,
        ],
        relicSets: [
            RELIC_SETS.DOT,
            RELIC_SETS.FIRE,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.SPEED,
        ],
        '2pc': [RELIC_SETS.SPEED],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.PANCOSMIC,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.ATK, RELIC_STATS.EFFECT_HIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.FIRE_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.BREAK_EFFECT, RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.LOWEST },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.HANYA,
        searchTerms: [],
        path: PATHS.HARMONY,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_DDD,
            LIGHTCONE_IDS.HARMONY_MOTP,
            LIGHTCONE_IDS.HARMONY_COGS,
            LIGHTCONE_IDS.HARMONY_PLANETARY,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.FLEET,
            PLANAR_SETS.KEEL,
            ...PLANAR_SETS_ENERGY,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.HERTA,
        searchTerms: [],
        path: PATHS.ERUDITION,
        element: ELEMENTS.ICE,
        lightcones: [
            ...LIGHTCONES_ERUDITION
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.ICE,
            RELIC_SETS.FOLLOWUP,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.SIGONIA,
            PLANAR_SETS.DURAN,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK, RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ICE_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.HIMEKO,
        searchTerms: [],
        path: PATHS.ERUDITION,
        element: ELEMENTS.FIRE,
        lightcones: [
            ...LIGHTCONES_ERUDITION,
            LIGHTCONE_IDS.ERUDITION_CHARMONY,
        ],
        relicSets: [
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.PIONEER,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.FIRE,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.SIGONIA,
            PLANAR_SETS.DURAN,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.KALPAGNI,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.FIRE_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.HOOK,
        searchTerms: [],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_MOLES,
            LIGHTCONE_IDS.DESTRUCTION_WOOF,
        ],
        relicSets: [
            RELIC_SETS.FIRE,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.FIRE_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.HUOHUO,
        searchTerms: ["huo huo"],
        path: PATHS.ABUNDANCE,
        element: ELEMENTS.WIND,
        lightcones: [
            LIGHTCONE_IDS.ABUNDANCE_HUOHUO,
            LIGHTCONE_IDS.ABUNDANCE_POSTOP,
            LIGHTCONE_IDS.ABUNDANCE_SHARED,
            LIGHTCONE_IDS.ABUNDANCE_QPQ,
            LIGHTCONE_IDS.ABUNDANCE_PERFECTTIMING,
        ],
        relicSets: [
            RELIC_SETS.HEAL,
            RELIC_SETS.SPEED,
            RELIC_SETS.HP,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.HP, RELIC_SETS.GUARD, RELIC_SETS.SPEED],
        '4pc': [RELIC_SETS.MUSKETEER],
        planarSets: [
            PLANAR_SETS.FLEET,
            PLANAR_SETS.KEEL,
            ...PLANAR_SETS_ENERGY,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.OUTGOING_HEALING, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.HP],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.JADE,
        searchTerms: [],
        path: PATHS.ERUDITION,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            LIGHTCONE_IDS.ERUDITION_JADE,
            ...LIGHTCONES_ERUDITION
        ],
        relicSets: [
            RELIC_SETS.QUANTUM,
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.VALOROUS,
        ],
        '2pc': [],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.DURAN,
            PLANAR_SETS.SIGONIA,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.SALSOTTO
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.QUANTUM_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.LOWEST },
        ]
    },
    {
        character: CHARACTERS.JIAOQIU,
        searchTerms: ['jq'],
        path: PATHS.NIHILITY,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_JIAOQIU,
            LIGHTCONE_IDS.NIHILITY_EOTP,
            LIGHTCONE_IDS.NIHILITY_SWEVENT,
            LIGHTCONE_IDS.NIHILITY_BS,
            LIGHTCONE_IDS.NIHILITY_PEARLS,
            LIGHTCONE_IDS.NIHILITY_SOLITARY
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
            RELIC_SETS.PIONEER,
            RELIC_SETS.WIND,
            RELIC_SETS.DOT
        ],
        '2pc': [RELIC_SETS.SPEED, RELIC_SETS.HP, RELIC_SETS.GUARD, RELIC_SETS.PIONEER],
        '4pc': [RELIC_SETS.WIND, RELIC_SETS.DOT],
        planarSets: [
            PLANAR_SETS.VONWACQ,
            PLANAR_SETS.PANCOSMIC,
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.LUSHAKA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.EFFECT_HIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.FIRE_DMG, RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.LOWEST },
        ]
    },
    {
        character: CHARACTERS.JING_YUAN,
        searchTerms: ["jy", "jingyuan"],
        path: PATHS.ERUDITION,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            ...LIGHTCONES_ERUDITION
        ],
        relicSets: [
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.PIONEER,
            RELIC_SETS.LIGHTNING,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.BANANA,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.LIGHTNING_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.JINGLIU,
        searchTerms: ["jl"],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.ICE,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_JINGLIU,
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_SECRETVOW,
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.ICE,
            RELIC_SETS.QUANTUM,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [RELIC_SETS.QUANTUM],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ICE_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.KAFKA,
        searchTerms: [],
        path: PATHS.NIHILITY,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_KAFKA,
            LIGHTCONE_IDS.NIHILITY_GNSW,
            LIGHTCONE_IDS.NIHILITY_FERMATA,
            LIGHTCONE_IDS.NIHILITY_SOLITARY,
        ],
        relicSets: [
            RELIC_SETS.DOT,
            RELIC_SETS.LIGHTNING,
        ],
        '2pc': [],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.LIGHTNING_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.LINGSHA,
        searchTerms: [],
        path: PATHS.ABUNDANCE,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.ABUNDANCE_LINGSHA,
            LIGHTCONE_IDS.ABUNDANCE_LUOCHA,
            LIGHTCONE_IDS.ABUNDANCE_HUOHUO,
            LIGHTCONE_IDS.ABUNDANCE_POSTOP,
            LIGHTCONE_IDS.ABUNDANCE_MULTIPLICATION,
            LIGHTCONE_IDS.ABUNDANCE_WHATISREAL,
        ],
        relicSets: [
            RELIC_SETS.HEAL,
            RELIC_SETS.SPEED,
            RELIC_SETS.BREAK,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.CAVALRY
        ],
        '2pc': [
            RELIC_SETS.SPEED,
            RELIC_SETS.BREAK,
            RELIC_SETS.WATCHMAKER,
        ],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.LUSHAKA,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.KALPAGNI,
            PLANAR_SETS.VONWACQ,
            PLANAR_SETS.TALIA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.OUTGOING_HEALING],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
        ]
    },
    {
        character: CHARACTERS.LUKA,
        searchTerms: [],
        path: PATHS.NIHILITY,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_GNSW,
            LIGHTCONE_IDS.NIHILITY_KAFKA,
            LIGHTCONE_IDS.NIHILITY_EOTP,
            LIGHTCONE_IDS.NIHILITY_SW,
            LIGHTCONE_IDS.NIHILITY_PEARLS,
        ],
        relicSets: [
            RELIC_SETS.PHYSICAL,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.SPEED,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.WATCHMAKER, RELIC_SETS.SPEED, RELIC_SETS.DOT, RELIC_SETS.VALOROUS, RELIC_SETS.CAVALRY],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.PANCOSMIC,
            PLANAR_SETS.TALIA,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.ATK, RELIC_STATS.EFFECT_HIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.PHYSICAL_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.LUOCHA,
        searchTerms: [],
        path: PATHS.ABUNDANCE,
        element: ELEMENTS.IMAGINARY,
        lightcones: [
            LIGHTCONE_IDS.ABUNDANCE_MULTIPLICATION,
            LIGHTCONE_IDS.ABUNDANCE_LUOCHA,
            LIGHTCONE_IDS.ABUNDANCE_HUOHUO,
            LIGHTCONE_IDS.ABUNDANCE_PERFECTTIMING,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.HEAL,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.HEAL, RELIC_SETS.SPEED, RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.SSS,
            ...PLANAR_SETS_ENERGY,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.OUTGOING_HEALING],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.LOWEST },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.LOWEST },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.LYNX,
        searchTerms: [],
        path: PATHS.ABUNDANCE,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            LIGHTCONE_IDS.ABUNDANCE_HUOHUO,
            LIGHTCONE_IDS.ABUNDANCE_BAILU,
            LIGHTCONE_IDS.ABUNDANCE_POSTOP,
            LIGHTCONE_IDS.ABUNDANCE_PERFECTTIMING,
            LIGHTCONE_IDS.ABUNDANCE_HHEVENT,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.HEAL,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.HEAL, RELIC_SETS.SPEED, RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [RELIC_SETS.MUSKETEER],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.OUTGOING_HEALING, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.HP],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.MARCH_7TH,
        searchTerms: ['m7'],
        path: PATHS.PRESERVATION,
        element: ELEMENTS.ICE,
        lightcones: [
            LIGHTCONE_IDS.PRESERVATION_GEPARD,
            LIGHTCONE_IDS.PRESERVATION_FX,
            LIGHTCONE_IDS.PRESERVATION_LANDAUS,
            LIGHTCONE_IDS.PRESERVATION_D1,
        ],
        relicSets: [
            RELIC_SETS.SHIELD,
            RELIC_SETS.SPEED,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
            RELIC_SETS.WIND,
        ],
        '2pc': [RELIC_SETS.SPEED, RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [RELIC_SETS.WIND, RELIC_SETS.SHIELD],
        planarSets: [
            PLANAR_SETS.BELEBOG,
            PLANAR_SETS.FLEET
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.EFFECT_HIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.MARCH_7TH_IMA,
        searchTerms: ['m7', 'm8', 'hm7', 'hunt 7th', 'march 8th', 'hunt march'],
        path: PATHS.HUNT,
        element: ELEMENTS.IMAGINARY,
        lightcones: [
            LIGHTCONE_IDS.HUNT_SEELE,
            LIGHTCONE_IDS.HUNT_RATIO,
            LIGHTCONE_IDS.HUNT_TOPAZ,
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_SILENCE,
            LIGHTCONE_IDS.HUNT_YANQING,
            LIGHTCONE_IDS.HUNT_BOOTHILL,
            LIGHTCONE_IDS.HUNT_SHADOWED,
            LIGHTCONE_IDS.HUNT_RIVER,
            LIGHTCONE_IDS.HUNT_ADVERSARIAL,
        ],
        relicSets: [
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.IMAGINARY,
            RELIC_SETS.PIONEER,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.BREAK,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [RELIC_SETS.CAVALRY],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.TALIA,
            PLANAR_SETS.KALPAGNI
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.IMAGINARY_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.LOWEST },
        ]
    },
    {
        character: CHARACTERS.MISHA,
        searchTerms: [],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.ICE,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_CLARA,
            LIGHTCONE_IDS.DESTRUCTION_NOWHERETORUN,
            LIGHTCONE_IDS.DESTRUCTION_MOLES,
        ],
        relicSets: [
            RELIC_SETS.ICE,
            RELIC_SETS.PIONEER,
            RELIC_SETS.HP,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ICE],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
        ],
    },
    {
        character: CHARACTERS.MOZE,
        searchTerms: [],
        path: PATHS.HUNT,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            LIGHTCONE_IDS.HUNT_RATIO,
            LIGHTCONE_IDS.HUNT_TOPAZ,
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_SILENCE,
            LIGHTCONE_IDS.HUNT_FV,
            LIGHTCONE_IDS.HUNT_CRUISING,
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.VALOROUS,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [...RELIC_SETS_ATK.filter(r => r !== RELIC_SETS.VALOROUS)],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.DURAN,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.SALSOTTO,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.LIGHTNING_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.LOWEST },
        ]
    },
    {
        character: CHARACTERS.NATASHA,
        searchTerms: [],
        path: PATHS.ABUNDANCE,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.ABUNDANCE_MULTIPLICATION,
            LIGHTCONE_IDS.ABUNDANCE_HUOHUO,
            LIGHTCONE_IDS.ABUNDANCE_BAILU,
            LIGHTCONE_IDS.ABUNDANCE_POSTOP,
            LIGHTCONE_IDS.ABUNDANCE_PERFECTTIMING,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.HEAL,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.HEAL, RELIC_SETS.HP, RELIC_SETS.SPEED, RELIC_SETS.GUARD],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.OUTGOING_HEALING],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.HP],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.PELA,
        searchTerms: [],
        path: PATHS.NIHILITY,
        element: ELEMENTS.ICE,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_SWEVENT,
            LIGHTCONE_IDS.NIHILITY_PEARLS,
        ],
        relicSets: [
            RELIC_SETS.WIND,
            RELIC_SETS.SPEED,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.GUARD,
            RELIC_SETS.HP,
        ],
        '2pc': [RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [RELIC_SETS.WIND],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.PENACONY,
            PLANAR_SETS.LUSHAKA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.EFFECT_HIT_RATE, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.QINGQUE,
        searchTerms: ["qq"],
        path: PATHS.ERUDITION,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            ...LIGHTCONES_ERUDITION
        ],
        relicSets: [
            RELIC_SETS.QUANTUM,
            RELIC_SETS.PIONEER,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.QUANTUM_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.RAPPA,
        searchTerms: [],
        path: PATHS.ERUDITION,
        element: ELEMENTS.IMAGINARY,
        disabled: true,
        lightcones: [],
        relicSets: [],
        '2pc': [],
        '4pc': [],
        planarSets: [],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [],
            },
        ],
        substats: []
    },
    {
        character: CHARACTERS.ROBIN,
        searchTerms: [],
        path: PATHS.HARMONY,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_ROBIN,
            LIGHTCONE_IDS.HARMONY_ROBINEVENT,
            LIGHTCONE_IDS.HARMONY_BLOOM,
            LIGHTCONE_IDS.HARMONY_BRONYA,
            LIGHTCONE_IDS.HARMONY_MOTP,
            LIGHTCONE_IDS.HARMONY_COGS,
            LIGHTCONE_IDS.HARMONY_CARVETHEMOON,
        ],
        relicSets: [
            RELIC_SETS.PHYSICAL,
            RELIC_SETS.MUSKETEER,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.SPEED, ...RELIC_SETS_ATK],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.FLEET,
            PLANAR_SETS.SSS,
            ...PLANAR_SETS_ENERGY,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK, RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ATK, RELIC_STATS.PHYSICAL_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.RUAN_MEI,
        searchTerms: ["rm", "ruanmei"],
        path: PATHS.HARMONY,
        element: ELEMENTS.ICE,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_RM,
            LIGHTCONE_IDS.HARMONY_MOTP,
            LIGHTCONE_IDS.HARMONY_COGS,
            LIGHTCONE_IDS.HARMONY_DDD,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.HP, RELIC_SETS.GUARD, RELIC_SETS.CAVALRY, RELIC_SETS.BREAK],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.FLEET,
            PLANAR_SETS.KEEL,
            PLANAR_SETS.PENACONY,
            PLANAR_SETS.TALIA,
            PLANAR_SETS.LUSHAKA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.SAMPO,
        searchTerms: [],
        path: PATHS.NIHILITY,
        element: ELEMENTS.WIND,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_GNSW,
            LIGHTCONE_IDS.NIHILITY_EOTP,
            LIGHTCONE_IDS.NIHILITY_FERMATA,
            LIGHTCONE_IDS.NIHILITY_SOLITARY,
        ],
        relicSets: [
            ...RELIC_SETS_ATK,
            RELIC_SETS.WIND,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.SPEED,
            RELIC_SETS.WATCHMAKER,
        ],
        '2pc': [RELIC_SETS.SPEED, RELIC_SETS.WATCHMAKER, RELIC_SETS.VALOROUS, RELIC_SETS.CAVALRY],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
            PLANAR_SETS.PANCOSMIC,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.TALIA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.EFFECT_HIT_RATE, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.WIND_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.ATK, RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.SEELE,
        searchTerms: [],
        path: PATHS.HUNT,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            LIGHTCONE_IDS.HUNT_SEELE,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_SILENCE,
        ],
        relicSets: [
            RELIC_SETS.QUANTUM,
            RELIC_SETS.PIONEER,
            ...RELIC_SETS_ATK
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
            PLANAR_SETS.SALSOTTO,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.QUANTUM_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.SERVAL,
        searchTerms: [],
        path: PATHS.ERUDITION,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            ...LIGHTCONES_ERUDITION,
            LIGHTCONE_IDS.ERUDITION_CHARMONY,
            LIGHTCONE_IDS.ERUDITION_PASSKEY,
        ],
        relicSets: [
            RELIC_SETS.LIGHTNING,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.PIONEER,
            RELIC_SETS.WATCHMAKER,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.WATCHMAKER, RELIC_SETS.VALOROUS, RELIC_SETS.CAVALRY],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SIGONIA,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.LIGHTNING_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.SILVER_WOLF,
        searchTerms: ["sw", "silverwolf"],
        path: PATHS.NIHILITY,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_SWEVENT,
            LIGHTCONE_IDS.NIHILITY_SW,
            LIGHTCONE_IDS.NIHILITY_PEARLS,
            LIGHTCONE_IDS.NIHILITY_ACHERON,
            LIGHTCONE_IDS.NIHILITY_WELT,
            LIGHTCONE_IDS.NIHILITY_EOTP,
            LIGHTCONE_IDS.NIHILITY_CHOREO,
        ],
        relicSets: [
            RELIC_SETS.QUANTUM,
            RELIC_SETS.PIONEER,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.SPEED,
        ],
        '2pc': [RELIC_SETS.WATCHMAKER, RELIC_SETS.SPEED, RELIC_SETS.CAVALRY],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.PENACONY,
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.LUSHAKA,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.TALIA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.EFFECT_HIT_RATE, RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.QUANTUM_DMG, RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.SPARKLE,
        searchTerms: [],
        path: PATHS.HARMONY,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_SPARKLE,
            LIGHTCONE_IDS.HARMONY_DDD,
            LIGHTCONE_IDS.HARMONY_PLANETARY,
            LIGHTCONE_IDS.HARMONY_BRONYA,
            LIGHTCONE_IDS.HARMONY_PNF,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.WIND,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.GUARD,
            RELIC_SETS.HP,
        ],
        '2pc': [RELIC_SETS.GUARD, RELIC_SETS.HP],
        '4pc': [RELIC_SETS.MUSKETEER, RELIC_SETS.WIND],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.PENACONY,
            PLANAR_SETS.LUSHAKA,
            PLANAR_SETS.FLEET,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.SUSHANG,
        searchTerms: [],
        path: PATHS.HUNT,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_SEELE,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_YANQING,
            LIGHTCONE_IDS.HUNT_SILENCE,
        ],
        relicSets: [
            RELIC_SETS.PHYSICAL,
            RELIC_SETS.PIONEER,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.BREAK,
            RELIC_SETS.WATCHMAKER,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.WATCHMAKER, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.SSS,
            PLANAR_SETS.TALIA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.PHYSICAL_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.BREAK_EFFECT, RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.TINGYUN,
        searchTerms: ["ty"],
        path: PATHS.HARMONY,
        element: ELEMENTS.LIGHTNING,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_DDD,
            LIGHTCONE_IDS.HARMONY_MOTP,
            LIGHTCONE_IDS.HARMONY_COGS,
            LIGHTCONE_IDS.HARMONY_PLANETARY,
            LIGHTCONE_IDS.HARMONY_BLOOM,
        ],
        relicSets: [
            RELIC_SETS.SPEED,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.WIND,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD
        ],
        '2pc': [RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [RELIC_SETS.WIND],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            ...PLANAR_SETS_ENERGY
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.ATK, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ATK, RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.TOPAZ,
        searchTerms: [],
        path: PATHS.HUNT,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.HUNT_TOPAZ,
            LIGHTCONE_IDS.HUNT_FV,
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_SILENCE,
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.FIRE,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.DURAN,
            PLANAR_SETS.BANANA,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.FIRE_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.MC_FIRE,
        searchTerms: ["fmc", "pmc", "fire mc", "preservation mc"],
        path: PATHS.PRESERVATION,
        element: ELEMENTS.FIRE,
        lightcones: [
            LIGHTCONE_IDS.PRESERVATION_GEPARD,
            LIGHTCONE_IDS.PRESERVATION_LANDAUS,
            LIGHTCONE_IDS.PRESERVATION_FX,
            LIGHTCONE_IDS.PRESERVATION_TREND,
            LIGHTCONE_IDS.PRESERVATION_D1,
            LIGHTCONE_IDS.PRESERVATION_DESTINY,
            LIGHTCONE_IDS.PRESERVATION_THISISME,
        ],
        relicSets: [
            RELIC_SETS.GUARD,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.SHIELD,
            RELIC_SETS.HP
        ],
        '2pc': [RELIC_SETS.SHIELD, RELIC_SETS.HP, RELIC_SETS.CAVALRY],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.TALIA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.DEF, RELIC_STATS.EFFECT_HIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.DEF, RELIC_STATS.FIRE_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ENERGY_REGEN, RELIC_STATS.DEF, RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.MC_IMAGINARY,
        searchTerms: ["hmc", "harmony mc"],
        path: PATHS.HARMONY,
        element: ELEMENTS.IMAGINARY,
        lightcones: [
            LIGHTCONE_IDS.HARMONY_RM,
            LIGHTCONE_IDS.HARMONY_MOTP,
            LIGHTCONE_IDS.HARMONY_DDD,
            LIGHTCONE_IDS.HARMONY_COGS,
        ],
        relicSets: [
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.BREAK,
            RELIC_SETS.CAVALRY,
            RELIC_SETS.SPEED,
            RELIC_SETS.WIND,
        ],
        '2pc': [RELIC_SETS.SPEED],
        '4pc': [RELIC_SETS.WIND],
        planarSets: [
            PLANAR_SETS.TALIA,
            PLANAR_SETS.KALPAGNI,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.LUSHAKA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.HP, RELIC_STATS.DEF],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.BREAK_EFFECT, RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.DEF, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.MC_PHYSICAL,
        searchTerms: ["dmc", "pmc", "physical mc", "destruction mc"],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_SECRETVOW,
            LIGHTCONE_IDS.DESTRUCTION_MOLES,
        ],
        relicSets: [
            RELIC_SETS.PHYSICAL,
            RELIC_SETS.PIONEER,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.PHYSICAL_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.WELT,
        searchTerms: [],
        path: PATHS.NIHILITY,
        element: ELEMENTS.IMAGINARY,
        lightcones: [
            LIGHTCONE_IDS.NIHILITY_ACHERON,
            LIGHTCONE_IDS.NIHILITY_SW,
            LIGHTCONE_IDS.NIHILITY_GNSW,
            LIGHTCONE_IDS.NIHILITY_CHOREO,
            LIGHTCONE_IDS.NIHILITY_WELT,
            LIGHTCONE_IDS.NIHILITY_KAFKA,
            LIGHTCONE_IDS.NIHILITY_SWEVENT,
            LIGHTCONE_IDS.NIHILITY_LOOP,
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.IMAGINARY,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.IZUMO,
            PLANAR_SETS.SSS,
            PLANAR_SETS.SALSOTTO,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.IMAGINARY_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.EFFECT_HIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.XUEYI,
        searchTerms: [],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.QUANTUM,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_FIREFLY,
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_PROMISE,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_MOLES,
            LIGHTCONE_IDS.DESTRUCTION_NOWHERETORUN,
        ],
        relicSets: [
            RELIC_SETS.CAVALRY,
            RELIC_SETS.QUANTUM,
            RELIC_SETS.BREAK,
            RELIC_SETS.WATCHMAKER,
            RELIC_SETS.PIONEER,
            RELIC_SETS.FOLLOWUP,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.WATCHMAKER, RELIC_SETS.DOT, RELIC_SETS.VALOROUS],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.TALIA,
            PLANAR_SETS.SALSOTTO,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.BREAK_EFFECT],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.BREAK_EFFECT, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.YANQING,
        searchTerms: ["yq"],
        path: PATHS.HUNT,
        element: ELEMENTS.ICE,
        lightcones: [
            LIGHTCONE_IDS.HUNT_SEELE,
            LIGHTCONE_IDS.HUNT_YANQING,
            LIGHTCONE_IDS.HUNT_SWORDPLAY,
            LIGHTCONE_IDS.HUNT_SILENCE,
            LIGHTCONE_IDS.HUNT_CRUISING,
            LIGHTCONE_IDS.HUNT_RIVER,
        ],
        relicSets: [
            RELIC_SETS.PIONEER,
            RELIC_SETS.ICE,
            ...RELIC_SETS_ATK,
        ],
        '2pc': [RELIC_SETS.DOT],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.RUTILANT,
            PLANAR_SETS.GLAMOTH,
            PLANAR_SETS.SSS,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_DMG],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.ICE_DMG],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
        ],
    },
    {
        character: CHARACTERS.YUKONG,
        path: PATHS.HARMONY,
        element: ELEMENTS.IMAGINARY,
        searchTerms: [],
        lightcones: [
            LIGHTCONE_IDS.HARMONY_BRONYA,
            LIGHTCONE_IDS.HARMONY_PLANETARY,
            LIGHTCONE_IDS.HARMONY_PNF,
            LIGHTCONE_IDS.HARMONY_MOTP,
            LIGHTCONE_IDS.HARMONY_COGS,
            LIGHTCONE_IDS.HARMONY_CARVETHEMOON,
            LIGHTCONE_IDS.HARMONY_BLOOM,
        ],
        relicSets: [
            RELIC_SETS.IMAGINARY,
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.HP,
            RELIC_SETS.GUARD,
        ],
        '2pc': [RELIC_SETS.HP, RELIC_SETS.GUARD],
        '4pc': [],
        planarSets: [
            PLANAR_SETS.KEEL,
            PLANAR_SETS.PENACONY,
            PLANAR_SETS.FLEET,
            PLANAR_SETS.LUSHAKA,
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.SPEED, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.IMAGINARY_DMG, RELIC_STATS.HP],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.SPEED, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.MEDIUM },
            { substat: RELIC_STATS.HP, weight: RELIC_WEIGHTS.LOWEST },
        ],
    },
    {
        character: CHARACTERS.YUNLI,
        searchTerms: ['yl'],
        path: PATHS.DESTRUCTION,
        element: ELEMENTS.PHYSICAL,
        lightcones: [
            LIGHTCONE_IDS.DESTRUCTION_YUNLI,
            LIGHTCONE_IDS.DESTRUCTION_BLADE,
            LIGHTCONE_IDS.DESTRUCTION_DHIL,
            LIGHTCONE_IDS.DESTRUCTION_CLARA,
            LIGHTCONE_IDS.DESTRUCTION_AEON,
            LIGHTCONE_IDS.DESTRUCTION_SKY4,
            LIGHTCONE_IDS.DESTRUCTION_NOWHERETORUN,
        ],
        relicSets: [
            RELIC_SETS.VALOROUS,
            RELIC_SETS.FOLLOWUP,
            RELIC_SETS.PIONEER,
            RELIC_SETS.HP,
            RELIC_SETS.PHYSICAL,
            ...RELIC_SETS_ATK
        ],
        '2pc': [
            RELIC_SETS.MUSKETEER,
            RELIC_SETS.DOT,
        ],
        '4pc': [RELIC_SETS.HP],
        planarSets: [
            PLANAR_SETS.DURAN,
            PLANAR_SETS.SALSOTTO
        ],
        mainStats: [
            {
                slot: RELIC_SLOTS.BODY,
                stats: [RELIC_STATS.CRIT_RATE],
            },
            {
                slot: RELIC_SLOTS.BOOTS,
                stats: [RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ORB,
                stats: [RELIC_STATS.PHYSICAL_DMG, RELIC_STATS.ATK],
            },
            {
                slot: RELIC_SLOTS.ROPE,
                stats: [RELIC_STATS.ATK, RELIC_STATS.ENERGY_REGEN],
            },
        ],
        substats: [
            { substat: RELIC_STATS.CRIT_RATE, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.CRIT_DMG, weight: RELIC_WEIGHTS.HIGHEST },
            { substat: RELIC_STATS.ATK, weight: RELIC_WEIGHTS.HIGH },
            { substat: RELIC_STATS.EFFECT_RES, weight: RELIC_WEIGHTS.LOWEST },
        ]
    },
];
