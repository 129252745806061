import React, { useState } from 'react';

import Calculator from '../../components/Calculator';

import { DOT_TYPES_KEYS, ELEMENTS_KEYS, ENEMY_TYPES_KEYS, PAGES } from '../../constants';

import BreakEffectCalculator from './BreakEffectCalculator';
import CritCalculator from './CritCalculator';
import DOTCalculator from './DOTCalculator';
import SuperBreakCalculator from './SuperBreakCalculator';

import './DamageCalculator.scss';

function DamageCalculator() {
    const [activeCalc, _setActiveCalc] = useState(0);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);

    const [element, setElement] = useState(ELEMENTS_KEYS.PHYSICAL);
    const [charLevel, setCharLevel] = useState(80);
    const [breakEffect, setBreakEffect] = useState('');
    const [enemyLevel, setEnemyLevel] = useState(95);
    const [enemyType, setEnemyType] = useState(ENEMY_TYPES_KEYS.ELITE_BOSS);
    const [dotStackCount, setDotStackCount] = useState('');
    const [enemyToughness, setEnemyToughness] = useState('');
    const [toughnessDamage, setToughnessDamage] = useState('');
    const [enemyMaxHP, setEnemyMaxHP] = useState('');
    const [resPen, setResPen] = useState(0);
    const [defIgnore, setDefIgnore] = useState(0);
    const [enemyDefReduction, setEnemyDefReduction] = useState(0);
    const [enemyVulnerability, setEnemyVulnerability] = useState(0);
    const [enemyRes, setEnemyRes] = useState(0);
    const [dotType, setDotType] = useState(DOT_TYPES_KEYS.BLEED);
    const [atk, setAtk] = useState('');
    const [skillMultiplier, setSkillMultiplier] = useState('');
    const [elementalDMG, setElementalDMG] = useState('');
    const [weaknessBroken, setWeaknessBroken] = useState(false);
    const [critRate, setCritRate] = useState('');
    const [critDMG, setCritDMG] = useState('');
    const [charWeaken, setCharWeaken] = useState(0);
    const [superBreakDMGPct, setSuperBreakDMGPct] = useState(100);

    const resetResults = () => {
        setResult(null);
    }

    const resetForm = () => {
        setElement(ELEMENTS_KEYS.PHYSICAL);
        setCharLevel(80);
        setBreakEffect('');
        setEnemyLevel(95);
        setEnemyType(ENEMY_TYPES_KEYS.ELITE_BOSS);
        setDotStackCount('');
        setEnemyToughness('');
        setToughnessDamage('');
        setEnemyMaxHP('');
        setResPen(0);
        setDefIgnore(0);
        setEnemyDefReduction(0);
        setEnemyVulnerability(0);
        setEnemyRes(0);
        setDotType(DOT_TYPES_KEYS.BLEED);
        setAtk('');
        setSkillMultiplier('');
        setElementalDMG('');
        setWeaknessBroken(false);
        setCharWeaken(0);
        setSuperBreakDMGPct(100);

        resetResults();
    }

    const setActiveCalc = val => {
        resetForm();
        _setActiveCalc(val);
    }

    const onChange = (e, setFunction) => {
        setResult(null);

        const val = e.target.value;
        setFunction(val.length ? Number(val) : val)
    }


    const onChangeRadio = (e, setFunction) => {
        resetResults();

        setFunction(e.target.checked);
    }

    const formParams = {
        resetResults,
        resetForm,
        onChange,
        onChangeRadio,
        error,
        setError,
        result,
        setResult,
    };

    return (
        <Calculator
            title={PAGES.DAMAGE_CALCULATOR.title}
            description={
                <div>
                    Please select what type of damage to calculate.<br />
                    <br />
                    Enemy Information can be obtained from <a href="https://srl.keqingmains.com/enemies" target="_blank" rel="noreferrer">KQM's Star Rail Library</a>.
                </div>
            }
            descriptionListItems={[]}
            buttonLabels={[
                'Standard (Crit)',
                'DoT',
                'Break',
                'Super Break'
            ]}
            activeCalc={activeCalc}
            setActiveCalc={setActiveCalc}
            extraWrapperClasses={{
                'calculator-padding': true,
            }}
        >
            {activeCalc === 0 && (
                <CritCalculator
                    charLevel={charLevel}
                    setCharLevel={setCharLevel}
                    atk={atk}
                    setAtk={setAtk}
                    critRate={critRate}
                    setCritRate={setCritRate}
                    critDMG={critDMG}
                    setCritDMG={setCritDMG}
                    skillMultiplier={skillMultiplier}
                    setSkillMultiplier={setSkillMultiplier}
                    elementalDMG={elementalDMG}
                    setElementalDMG={setElementalDMG}
                    resPen={resPen}
                    setResPen={setResPen}
                    defIgnore={defIgnore}
                    setDefIgnore={setDefIgnore}
                    enemyDefReduction={enemyDefReduction}
                    setEnemyDefReduction={setEnemyDefReduction}
                    enemyVulnerability={enemyVulnerability}
                    setEnemyVulnerability={setEnemyVulnerability}
                    enemyRes={enemyRes}
                    setEnemyRes={setEnemyRes}
                    weaknessBroken={weaknessBroken}
                    setWeaknessBroken={setWeaknessBroken}
                    enemyLevel={enemyLevel}
                    setEnemyLevel={setEnemyLevel}
                    charWeaken={charWeaken}
                    setCharWeaken={setCharWeaken}
                    formParams={formParams}
                />
            )}
            {activeCalc === 1 && (
                <DOTCalculator
                    dotType={dotType}
                    setDotType={setDotType}
                    charLevel={charLevel}
                    setCharLevel={setCharLevel}
                    atk={atk}
                    setAtk={setAtk}
                    skillMultiplier={skillMultiplier}
                    setSkillMultiplier={setSkillMultiplier}
                    elementalDMG={elementalDMG}
                    setElementalDMG={setElementalDMG}
                    resPen={resPen}
                    setResPen={setResPen}
                    defIgnore={defIgnore}
                    setDefIgnore={setDefIgnore}
                    enemyLevel={enemyLevel}
                    setEnemyLevel={setEnemyLevel}
                    enemyDefReduction={enemyDefReduction}
                    setEnemyDefReduction={setEnemyDefReduction}
                    enemyVulnerability={enemyVulnerability}
                    setEnemyVulnerability={setEnemyVulnerability}
                    enemyRes={enemyRes}
                    setEnemyRes={setEnemyRes}
                    dotStackCount={dotStackCount}
                    setDotStackCount={setDotStackCount}
                    weaknessBroken={weaknessBroken}
                    setWeaknessBroken={setWeaknessBroken}
                    formParams={formParams}
                />
            )}
            {activeCalc === 2 && (
                <BreakEffectCalculator
                    element={element}
                    setElement={setElement}
                    charLevel={charLevel}
                    setCharLevel={setCharLevel}
                    breakEffect={breakEffect}
                    setBreakEffect={setBreakEffect}
                    enemyLevel={enemyLevel}
                    setEnemyLevel={setEnemyLevel}
                    enemyType={enemyType}
                    setEnemyType={setEnemyType}
                    dotStackCount={dotStackCount}
                    setDotStackCount={setDotStackCount}
                    enemyToughness={enemyToughness}
                    setEnemyToughness={setEnemyToughness}
                    enemyMaxHP={enemyMaxHP}
                    setEnemyMaxHP={setEnemyMaxHP}
                    resPen={resPen}
                    setResPen={setResPen}
                    defIgnore={defIgnore}
                    setDefIgnore={setDefIgnore}
                    enemyDefReduction={enemyDefReduction}
                    setEnemyDefReduction={setEnemyDefReduction}
                    enemyVulnerability={enemyVulnerability}
                    setEnemyVulnerability={setEnemyVulnerability}
                    enemyRes={enemyRes}
                    setEnemyRes={setEnemyRes}
                    formParams={formParams}
                />
            )}
            {activeCalc === 3 && (
                <SuperBreakCalculator
                    charLevel={charLevel}
                    setCharLevel={setCharLevel}
                    breakEffect={breakEffect}
                    setBreakEffect={setBreakEffect}
                    enemyLevel={enemyLevel}
                    setEnemyLevel={setEnemyLevel}
                    resPen={resPen}
                    setResPen={setResPen}
                    defIgnore={defIgnore}
                    setDefIgnore={setDefIgnore}
                    enemyDefReduction={enemyDefReduction}
                    setEnemyDefReduction={setEnemyDefReduction}
                    enemyVulnerability={enemyVulnerability}
                    setEnemyVulnerability={setEnemyVulnerability}
                    enemyRes={enemyRes}
                    setEnemyRes={setEnemyRes}
                    toughnessDamage={toughnessDamage}
                    setToughnessDamage={setToughnessDamage}
                    superBreakDMGPct={superBreakDMGPct}
                    setSuperBreakDMGPct={setSuperBreakDMGPct}
                    formParams={formParams}
                />
            )}
        </Calculator>
    );
}

export default DamageCalculator;
