import { PAGES } from '../../../constants.js';

import SidePanelNavItem from './SidePanelNavItem';

import './SidePanel.scss';

function SidePanel({
    setIsSidePanelOpen,
}) {
    return (
        <div className="sidePanel">
            <div className="sidePanel-left">
                <div className="sidePanel-top">
                    <button
                        className="sidePanel-close-wrapper"
                        onClick={() => setIsSidePanelOpen(false)}
                        tabIndex="0"
                    >
                        X
                    </button>
                </div>
                <SidePanelNavItem
                    page={PAGES.HOME}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                    isFirst
                />
                {/* <SidePanelNavItem
                    page={PAGES.CHARACTER_BUILDS}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.RELIC_SALVAGE}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.LIGHTCONE_ASSIGNER}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.SET_ASSIGNER}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                /> */}
                <SidePanelNavItem
                    page={PAGES.EHR_CALCULATOR}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.PULL_CHANCE_CALCULATOR}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.DAMAGE_CALCULATOR}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.BRONYA_SPEED_CALCULATOR}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.SPEED_DECIMAL_CHECKER}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.ABOUT}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
                <SidePanelNavItem
                    page={PAGES.PRIVACY_POLICY}
                    setIsSidePanelOpen={setIsSidePanelOpen}
                />
            </div>
            <div
                className="sidePanel-right"
                onClick={() => setIsSidePanelOpen(false)}
            />
        </div>
    );
}

export default SidePanel;
