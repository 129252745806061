import { LIGHTCONE_IDS, LIGHTCONE_TYPES } from './lightcone_helpers.js';
import { IMG_LIGHTCONES } from '../../assets/lightcones';

export const LIGHTCONES_HUNT = [
    {
        name: LIGHTCONE_IDS.HUNT_ADVERSARIAL,
        image: IMG_LIGHTCONES.HUNT_ADVERSARIAL,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_ARROW,
        image: IMG_LIGHTCONES.HUNT_ARROW,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_ARROWS,
        image: IMG_LIGHTCONES.HUNT_ARROWS,
        rarity: 3,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_BOOTHILL,
        image: IMG_LIGHTCONES.HUNT_BOOTHILL,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.HUNT_CRUISING,
        image: IMG_LIGHTCONES.HUNT_CRUISING,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_HERTA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_FV,
        image: IMG_LIGHTCONES.HUNT_FV,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.HUNT_RATIO,
        image: IMG_LIGHTCONES.HUNT_RATIO,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.HUNT_FEIXIAO,
        image: IMG_LIGHTCONES.HUNT_FEIXIAO,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.HUNT_RETURN,
        image: IMG_LIGHTCONES.HUNT_RETURN,
        rarity: 4,
        type: LIGHTCONE_TYPES.BATTLEPASS,
    },
    {
        name: LIGHTCONE_IDS.HUNT_RIVER,
        image: IMG_LIGHTCONES.HUNT_RIVER,
        rarity: 4,
        type: LIGHTCONE_TYPES.STORE_MOC,
    },
    {
        name: LIGHTCONE_IDS.HUNT_SEELE,
        image: IMG_LIGHTCONES.HUNT_SEELE,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.HUNT_SILENCE,
        image: IMG_LIGHTCONES.HUNT_SILENCE,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_SHADOWED,
        image: IMG_LIGHTCONES.HUNT_SHADOWED,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_SUBSCRIBE,
        image: IMG_LIGHTCONES.HUNT_SUBSCRIBE,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_SWORDPLAY,
        image: IMG_LIGHTCONES.HUNT_SWORDPLAY,
        rarity: 4,
        type: LIGHTCONE_TYPES.GACHA,
    },
    {
        name: LIGHTCONE_IDS.HUNT_TOPAZ,
        image: IMG_LIGHTCONES.HUNT_TOPAZ,
        rarity: 5,
        type: LIGHTCONE_TYPES.SIGNATURE,
    },
    {
        name: LIGHTCONE_IDS.HUNT_YANQING,
        image: IMG_LIGHTCONES.HUNT_YANQING,
        rarity: 5,
        type: LIGHTCONE_TYPES.STORE_STARLIGHT,
    },
];
