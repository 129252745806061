import { Link } from "react-router-dom";

import { PAGES } from '../../constants';

import Tool from './Tool'

import './Home.scss';

function Home() {
    return (
        <div className="home-wrapper">
            <div className="home">
                <div className="home-content">
                    {/* <h1 className="home-title">
                        Welcome to<br /><b>HSR Tools</b>!
                    </h1>
                    <h2 className="home-subtitle">
                        An unofficial collection of helpful tools for <b>Honkai: Star Rail</b>.
                    </h2> */}
                    {/* <h4 className="home-subtitle-2">
                        Updated for <span class="color-green">Version 2.5</span>
                    </h4> */}
                    <div className="home-section">
                        {/* <div className="home-section-title color-purple">
                            What's New?
                        </div> */}
                        <div className="home-section-description">
                            <h4>Thank you for using HSRTools!</h4>

                            This site was originally created with the goal of making Honkai Star Rail less daunting for new players.
                            I'm so happy that I was able to help people, and I'm thankful to everyone who used the site.<br />
                            <br />
                            However, I am no longer interested in maintaining it. I'm proud of what I've created, but the time spent maintaining
                            it has begun to feel more like a chore than a passion project.<br />
                            <br />
                            Non-character specific tools (e.g. Damage Calculator, EHR Calculator) will remain online until the domain expires,
                            but I've taken down everything character-specific as I don't want to host information that may be out of date.
                            These tools will remain online until the domain expires sometime in January 2025.<br />
                            <br />
                            Ads and the Donate button have also been taken down.<br />
                            <br />
                            Thanks for understanding and thanks again for using HSR Tools! {`<3`}
                        </div>
                    </div>
                    <div className="home-section">
                        <div className="home-section-title color-purple">
                            Tools
                        </div>
                        <div className="home-section-tools">
                            {/* <Tool
                                link={`/${PAGES.CHARACTER_BUILDS.link}`}
                                gradient={['#0f8551', '#084a2d']}
                                title={PAGES.CHARACTER_BUILDS.title}
                                description='How should I build this character?'
                            />
                            <Tool
                                link={`/${PAGES.RELIC_SALVAGE.link}`}
                                gradient={['#530f7d', '#27073b']}
                                title={PAGES.RELIC_SALVAGE.title}
                                description='Which character can use this piece?'
                            /> */}
                            {/* <Tool
                                link={`/${PAGES.LIGHTCONE_ASSIGNER.link}`}
                                gradient={['#7f4f9e', '#5a3770']}
                                title={PAGES.LIGHTCONE_ASSIGNER.title}
                                description='Which character can use this Light Cone?'
                            />
                            <Tool
                                link={`/${PAGES.SET_ASSIGNER.link}`}
                                gradient={['#0f4c8c', '#08294d']}
                                title={PAGES.SET_ASSIGNER.title}
                                description='Which character can use this Set?'
                            /> */}
                            <Tool
                                link={`/${PAGES.EHR_CALCULATOR.link}`}
                                gradient={['#0f8585', '#094a4a']}
                                title={PAGES.EHR_CALCULATOR.title}
                                description='What is the Actual Chance of landing a debuff?'
                            />
                            <Tool
                                link={`/${PAGES.PULL_CHANCE_CALCULATOR.link}`}
                                gradient={['#7e52f7', '#5336a3']}
                                title={PAGES.PULL_CHANCE_CALCULATOR.title}
                                description='How likely am I to get a character or lightcone within x pulls?'
                            />
                            <Tool
                                link={`/${PAGES.DAMAGE_CALCULATOR.link}`}
                                gradient={['#2f0f80', '#1c094d']}
                                title={PAGES.DAMAGE_CALCULATOR.title}
                                description='How much damage can I expect?'
                            />
                            <Tool
                                link={`/${PAGES.BRONYA_SPEED_CALCULATOR.link}`}
                                gradient={['#11a63d', '#0a6325']}
                                title={PAGES.BRONYA_SPEED_CALCULATOR.title}
                                description='What Speeds do you need on Bronya and your DPS?'
                            />
                            <Tool
                                link={`/${PAGES.SPEED_DECIMAL_CHECKER.link}`}
                                gradient={['#1368c2', '#0d4582']}
                                title={PAGES.SPEED_DECIMAL_CHECKER.title}
                                description='Exactly how much speed do your units have?'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Home;